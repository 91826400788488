

.hobbie-container {
    padding: 15px 15px;
}

.hobbie-container .hobbie-icon {
    display: block;
    font-size: 3.5rem;
    color: var(--content-color);
    margin-bottom: 10px;
}

.hobbie-container .hobbie-title {
    font-size: 1.2rem;
    font-weight: 500;
}