.go-color {
    background-color: rgb(114, 196, 226);
}
.csharp-color {
    background-color: #AB68DA;
}
.python-color {
    background-color: #E8B93C;
}
.swift-color {
    background-color: #E77369;
}
.html-color {
    background-color: #F09F67;
}
.css-color {
    background-color: #6971E6;
}
.javascript-color {
    background-color: #E7D66C;
}
.react-color {
    background-color: #5AD3E8;
}
.php-color {
    background-color: #8689D1;
}
.mysql-color {
    background-color: #6588CD;
}
.postgresql-color {
    background-color: #6689CB;
}
.nginx-color {
    background-color: #76C68F;
}
.c-color {
    background-color: #A9ADB4;
}
.nodejs-color {
    background-color: #1c7c34;
}
.cpp-color {
    background-color: #5BB0C9;
}
.docker-color {
    background-color: #62D0DF;
}
.atom-color {
    background-color: #53D385;
}
.visualstudio-color {
    background-color: #A464CB;
}
.linux-color {
    background-color: #DFCC62;
}
.arduino-color {
    background-color: rgb(98, 209, 223);
}
.raspberry-color {
    background-color: rgb(201, 71, 99);
}
.unity-color {
    background-color: rgb(88, 88, 88);
}
.sqlserver-color {
    background-color: rgb(170, 26, 57);
}
.flutter-color {
    background-color: rgb(74, 199, 221);
}
.dotnetcore-color {
    background-color: #623697;
}