
body {
    background-color: var(--background-color);
}



/* SECTION INTRO */
/* SECTION INTRO */

.section-intro {
    padding: 0 !important;
}

.section-intro .section-intro-center {
    padding-left: 20%;
}

.section-intro .speech-bubble {
    margin-left: 0.33em;
    font-size: 1.5rem;
    background-color: var(--background-color);
}
.section-intro .speech-bubble::after {
    border-top-color: var(--background-color);
}

.section-intro .section-intro-titles {
    margin-top: 7%;
}

.section-intro .section-intro-titles .title {
    font-size: 45px;
    font-weight: 400;
}
.section-intro .section-intro-titles .subtitle {
    font-size: 30px;
    font-weight: 200;
}


.section-intro .section-intro-img {
    max-width: 70%;
    height: auto;
    display: inline-block;
}

@media (max-width: 991px) {
    .section-intro .section-intro-center {
        padding-top: 20%;
        padding-bottom: 20%;
    }
    .section-intro .section-intro-img {
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    .section-intro {
        text-align: center;
    }
    .section-intro .section-intro-center {
        padding-top: 10%;
        padding-left: 0;
        padding-bottom: 7%;
    }
    .section-intro .section-intro-img {
        max-width: 60%;
        float: none !important;
        margin: auto;
    }

    .section-intro .speech-bubble {
        font-size: 1.2rem;
    }
    .section-intro .speech-bubble::after {
        left: 50%;
        margin-left: -20px;
    }

    .section-intro .section-intro-titles .title {
        font-size: 2.5rem;
    }
    .section-intro .section-intro-titles .subtitle {
        font-size: 1.56rem;
    }
}

@media (min-width: 768px) {
    .section-intro .speech-bubble::after {
        border-left: 0;
    }
}

/* ------------------ */
/* ------------------ */






/* SECTION ABOUTEME */
/* SECTION ABOUTEME */

.section-aboutme .infos-table {
    list-style: none;
    padding: 0;
    margin: 0;
}

.section-aboutme .area {
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
}

.section-aboutme .infos-table .row {
    margin-bottom: 0.5rem;
}
.section-aboutme .infos-table .row:last-child {
    margin-bottom: 0;
}

.section-aboutme .infos-table .title {
    display: inline-block;
    text-align: left;
    width: 100%;
    color: #333333;
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    /* margin-right: 0.6rem; */
}

.section-aboutme .infos-table .value {
    display: inline-block;
    text-align: left;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    overflow-wrap: break-word;
    line-height: 20px;
    color: #9da0a7;
    /* margin-left: 0.6rem; */
}

.section-aboutme .section-aboutme-contact-btns {
    margin-top: 3em;
}
.section-aboutme .section-aboutme-contact-btns .animated {
    display: inline-block;
}
.section-aboutme .section-aboutme-contact-btns .btn {
    padding: 0.9rem 1.05rem;
    box-shadow: none;
    border: 2px solid var(--content-color);
    background-color: var(--content-color) !important;
    border-radius: 50%;
}
.section-aboutme .section-aboutme-contact-btns .btn:hover {
    background-color: white !important;
    color: var(--content-color);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.section-aboutme .section-aboutme-contact-btns .btn i {
    font-size: 1.2rem;
}


@media (max-width: 767px) {
    /* .section-aboutme .infos-table .row {
        margin-bottom: 0.5rem;
    }
    .section-aboutme .infos-table .row:last-child {
        margin-bottom: 0;
    } */
    .section-aboutme .infos-table .title {
        text-align: center;
    }
    .section-aboutme .infos-table .value {
        text-align: center;
    }
}

/* ------------------ */
/* ------------------ */






/* SECTION SKILLS */
/* SECTION SKILLS */

.section-skills .content-filter {
    margin-top: 40px;
}

/* ------------------ */
/* ------------------ */






/* SECTION PROJECTS */
/* SECTION PROJECTS */

.section-projects .timeline .timeline-content-enum {
    margin-top: 0.5em;
}

.section-projects .timeline .timeline-content-enum p {
    margin: 0.3em 0;
}



.section-projects .cv-project {
    /* background-color: var(--content-color); */
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 30px 20px;
    color: var(--content-color);
}

.section-projects .cv-project h2 {
    /* color: white; */
    font-size: 2.4rem;
    font-weight: 400;
}

.section-projects .cv-project .cv-lang-icons {
    margin-top: 40px;
}
.section-projects .cv-project .cv-lang-icons .animated {
    display: inline-block;
}
.section-projects .cv-project .cv-lang-icons i {
    /* color: white; */
    font-size: 4rem;
    margin: 0 10px;
}


/* ------------------ */
/* ------------------ */







/* SECTION PROJECTS */
/* SECTION PROJECTS */

.section-languages .languages {
    margin: auto;
}


/* ------------------ */
/* ------------------ */