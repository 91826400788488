.language-skill {
    margin: 25px 0;
}

.language-skill .title {
    text-align: right;
}

.language-skill h4 {
    color: rgb(65, 65, 65);
    font-size: 1.9rem;
    font-weight: 400;
    margin: 0;
}

.language-skill .rounds {
    text-align: left;
}

.language-skill .animated {
    display: inline-block;
}

.language-skill .round {
    height: 30px;
    width: 30px;
    border: solid 2px var(--content-color);
    margin: 0 5px;
    border-radius: 50%;
}

.language-skill .full {
    background-color: var(--content-color);
}

@media (max-width: 991px) {
    .language-skill {
        margin: 15px 0;
    }

    .language-skill h4 {
        font-size: 1.4rem;
    }
    .language-skill .round {
        height: 20px;
        width: 20px;
        margin: 0 3px;
    }
}

@media (max-width: 767px) {
    .language-skill .column {
        margin: 5px 0;
    }

    .language-skill .rounds {
        text-align: center;
    }
    .language-skill .title {
        text-align: center;
    }
}