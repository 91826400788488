// Treeview
.treeview {
  &.w-20 {
    width: 20rem;
  }
  .rotate {
    cursor: pointer;
    user-select: none;
    transition: all .1s linear;
    font-size: .8rem;
    vertical-align: text-top;
    margin-top: .2rem;
    &.down {
      transform: rotate(90deg);
    }
  }
  .nested {
    display: none;
  }
  .active {
    display: block;
  }
  ul {
    list-style-type: none;
  }
  .ic-w {
    width: 1.3rem;
  }
}

.treeview-animated {
  &.w-20 {
    width: 20rem;
  }
  ul {
    position: relative;
    list-style: none;
    padding-left: 1em;
  }

  .treeview-animated-list {
    li {
      padding: 0.2em 0 0 0.2em;
    }

    .treeview-animated-items {
      .nested {
        &::before {
          content: "";
          display: block;
          position: absolute;
          background-color: grey;
          left: 5px;
          width: 5px;
          height: 100%;
        }
      }
      .closed{
        display: block;
        padding: 0.2em 0.2em 0.2em 0.4em;
        margin-right: 0;
        border-top-left-radius: 0.3em;
        border-bottom-left-radius: 0.3em;
        &:hover{
          background-color: rgb(140, 185, 255);
        }
        .fa-angle-right{
          transition: all .1s linear;
          font-size: .8rem;
          &.down{
            position: relative;
            color: #f8f9fa;
            transform: rotate(90deg);
          }
        }
      }
      .open{
        transition: all .1s linear;
        background-color: rgb(50, 160, 255);
        &:hover{

    color: #f8f9fa;
    background-color: rgb(50, 160, 255);
        }
        span{
          color: #f8f9fa;
        }
      }
    }

    .treeview-animated-element {
      padding: 0.2em 0.2em 0.2em 0.6em;
      cursor: pointer;
      transition: all .1s linear;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;

      &:hover {
        background-color: rgb(140, 185, 255);
      }

      &.opened {
        color: #f8f9fa;
        background-color: rgb(50, 160, 255);

        &:hover {
          color: #f8f9fa;
          background-color: rgb(50, 160, 255);
        }
      }
    }
  }

}