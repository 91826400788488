
.mail-form .md-form {
    margin-bottom: 0;
}

.mail-form .invalid-input-text {
    padding: 5px 7px;
    background-color: rgb(233, 73, 73);
    color: white;
    font-weight: 400;
    font-size: 0.9rem;
}

.mail-form #mail-form-btnview-sm {
    text-align: center;
    display: none;
}
.mail-form #mail-form-btnview-sm button {
    margin-top: 25px;
}
.mail-form button {
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 15%;
}
.mail-form .badge {
    padding: 3px 15px;
}

@media (max-width: 767px) {
    .mail-form {
        margin: 0 25px;
    }
    .mail-form #mail-form-btnview-lg {
        display: none;
    }
    .mail-form #mail-form-btnview-sm {
        display: block;
    }
}