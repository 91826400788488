.speech-bubble {
	position: relative;
	background: var(--content-color);
    border-radius: .1em;
    padding: 0.18rem 1rem;
    font-weight: 700;
    display: inline-block;
	color: white;
	font-family: 'Raleway', 'sans-serif';
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 10px;
	left: 1rem;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-top-color: var(--content-color);
	border-bottom: 0;
	margin-left: -10px;
	margin-bottom: -20px;
}