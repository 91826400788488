.content-filter {
    margin-bottom: 2rem;
}

.content-filter .filter-group {
    display: inline-block;
    position: relative;
}

.content-filter .filter-btn {
    border: 0;
    background-color: transparent;
    margin-right: 20px;
    color: black;
    font-weight: 500;
    text-transform: capitalize;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
}
.content-filter .filter-btn:last-child {
    margin: 0;
}
.content-filter .filter-btn:hover {
    color: rgb(131, 131, 131);
}

.content-filter .filter-bar {
    height: 5px;
    margin: 6px auto 0;
    background-color: rgb(230, 230, 230);
    position: relative;
    border-radius: 5px;
}

.content-filter .filter-line {
    top: 0;
    height: 100%;
    display: block;
    position: relative;
    border-radius: 5px;
    -webkit-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -moz-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}





.content-filter .content-grid {
    position: relative;
    margin-top: 40px;
}

.content-filter .content-grid .row:first-child {
    margin-top: 0;
}
.content-filter .content-grid .row {
    margin-top: 20px;
}

.content-filter .content-grid .animated {
    height: 100%;
}

@media (max-width: 991px) {
    .content-filter .content-grid .content-col {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .content-filter .content-grid .row {
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .content-filter .content-grid .content-col {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}