.timeline {
    padding: 2rem 1.5rem;
    background-color: rgb(245, 245, 245);
    border-radius: 1rem;
}

@media (max-width: 991px) {
    .timeline {
        padding: .1rem .5rem;
    }
}

.timeline .vertical-timeline::before {
    background-color: grey;
    z-index: 0;
}

.timeline .vertical-timeline::after {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 0%;
    width: 4px;
    background: var(--content-color) !important;
    z-index: 1;
    display: block;
}

@media only screen and (min-width: 1170px) {
    .vertical-timeline.vertical-timeline--two-columns::after {
        left: 50%;
        margin-left: -2px;
    }
}


.timeline .vertical-timeline .vertical-timeline-element-icon {
    box-shadow: none;
    align-items: center;
    display: grid;
    text-align: center;
    transition: 0.5s;
}
.timeline .vertical-timeline .active-icon {
    box-shadow: 0 0 0 4px var(--content-color),inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05);
}

.timeline .icon {
    font-size: 1.6rem;
    color: rgb(65, 65, 65);
}

.timeline .vertical-timeline .vertical-timeline-element {
    z-index: 2;
}

.timeline .vertical-timeline .timeline-element-tags .vertical-timeline-element-content {
    padding-top: 0.8rem;
}
.timeline .vertical-timeline .vertical-timeline-element-content {
    /* border-top: 3px solid white; */
    transition: 0.2s;
}
.timeline .vertical-timeline .vertical-timeline-element-content-active {
    border-top: 3px solid var(--content-color);
}

.timeline .vertical-timeline .vertical-timeline-element-title {
    font-weight: 400;
}

@media (max-width: 1170px) {
    .timeline .icon {
        font-size: 1.4rem;
    }
}

@media (max-width: 767px) {
    .timeline .vertical-timeline .vertical-timeline-element-title {
        font-size: 1.2rem;
    }
    .timeline .vertical-timeline .vertical-timeline-element-subtitle {
        font-size: 1.1rem;
    }
}

.timeline .vertical-timeline .vertical-timeline-element-content .tags {
    margin-bottom: 0.5rem;
}
.timeline .vertical-timeline .vertical-timeline-element-content .tags .badge {
    margin: 0 3px;
}

.timeline .vertical-timeline .vertical-timeline-element-content .main-image {
    max-width: 100%;
    margin-top: 5px;
}

.timeline .vertical-timeline .vertical-timeline-element-content .main-btn-link {
    border-radius: 1rem;
    font-weight: bold;
    color: white;
    margin-top: 1rem;
    padding: 0.6rem 1.5rem;
    /* float: right; */
}