body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* CSS Global */
:root{
  --background-color: #35c784;
  --content-color: #46e29a;
}

body {
  font-family: 'Roboto', sans-serif;
}
h1 {
  font-family: 'Raleway', sans-serif;
}
h2 {
  font-family: 'Raleway', sans-serif;
}
p {
  font-weight: normal;
}

@media (max-width: 767px) {
  .container {
    max-width: none !important;
  }
}