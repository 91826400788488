
.empty-nav {
    height: 90px;
}

.navbar-container {
    margin-top: 30px;
    -webkit-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -moz-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.navbar-container-on-scroll {
    margin-top: 0;
    max-width: 100%;
    position: fixed;
    top: 0;
    z-index: 1023;
}

.navbar {
    background-color: var(--background-color);
    box-shadow: none;
    border-bottom: solid 1px white;
    /* padding-left: 15px;
    padding-right: 15px; */
    -webkit-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -moz-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}