
.section {
    padding-top: 3rem;
    padding-bottom: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;

    margin-top: 2rem;
    margin-bottom: 2rem;
    
    background-color: white;

    border-radius: 0.5rem;
}

.section-title {
    margin-bottom: 1.3rem;
}

.section-title i {
    /* color: #35c784; */
    margin-right: 1rem;
    display: inline-block;
}

.section-title h2 {
    font-size: 2.5rem;
    font-weight: 700;
    display: inline-block;
} 

/* Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) {
} */

/* // Medium devices (tablets, less than 768px) */
@media (max-width: 767px) {
    .section {
        padding-top: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .section-title {
        margin-bottom: 0.8rem;
    }
    .section-title h2 {
        font-size: 2rem;
    }
    .section-title i {
        margin-right: 0.5rem;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991px) {
    .section {
        padding-top: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .section-title {
        margin-bottom: 1rem;
    }
    .section-title h2 {
        font-size: 2.2rem;
    }
    .section-title i {
        margin-right: 0.8rem;
    }
}





@media (max-width: 767px) {
    .section-timeline {
        padding-right: 0;
        padding-left: 0;
    }
    .section-timeline .section-title {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}