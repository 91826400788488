.skill {
    box-shadow: 0px 3px 10px 0px rgba(1, 1, 1, 0.164);
    border-radius: 1rem;
    padding-top: 20px;
    padding-bottom: 0px;
    display: block;
    height: 100%;
    -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -moz-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

/* .skill:hover {
    box-shadow: 10px 20px 20px 10px rgba(1, 1, 1, 0.164);
    background-color: white !important;
    color: var(--content-color) !important;
} */

.skill:hover .skill-footer {
    background-color: rgba(255, 255, 255, 0.534);
}

.skill-head {
    color: white;
}

.skill .skill-head i {
    font-size: 6rem;
}

.skill .skill-head h4 {
    margin-top: 1.2rem;
    font-size: 1.7rem;
    font-weight: 400;
}

.skill .skill-content {
    margin-top: 8px;
}



.skill .skill-footer {
    background-color: rgba(255, 255, 255, 0.25);
    margin-top: 17px;
    padding: 7px 7px;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -moz-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.skill .skill-footer .skill-outbar {
    background-color: rgb(225, 225, 225);
    border-radius: 5px;
    height: 5px;
}

.skill .skill-footer .skill-bar {
    height: 100%;
    -webkit-transition: all 1.2s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -moz-transition: all 1.2s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 1.2s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.skill .skill-footer .skill-bar-text {
    font-size: 0.8rem;
    font-weight: bold;
    padding: 1px 5px;
    border-radius: 3px;
    position: relative;
    color: white;
    -webkit-transition: all 1.2s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -moz-transition: all 1.2s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 1.2s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}