// Badges
.badge {
  box-shadow: $z-depth-1;
  border-radius: $border-radius-base;
  color: $white !important;
}
.badge-pill {
  border-radius: $badge-pill-border-radius;
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
}
@each $name, $color in $basic-mdb-colors {
  @include make-badge($name, $color);
}
